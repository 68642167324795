<template>
  <div>
    <el-dialog
      :visible.sync="showModal"
      fullscreen
      :show-close="false"
      custom-class="editContentItemModal"
      destroy-on-close
    >
      <div>
        <el-button class="cancelBtn" @click="handleCancel">{{
          __("Cancel")
        }}</el-button>
      </div>
      <div>
        <el-table
          ref="portalTablesRef"
          :data="mapData"
          :default-expand-all="true"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">{{
              scope.row.mapping_helper_json
            }}</template>
          </el-table-column>
          <el-table-column :label="__('migration_map_id')">
            <template slot-scope="scope">{{
              scope.row.migration_map_id
            }}</template>
          </el-table-column>
          <el-table-column :label="__('Studio6 Table')">
            <template slot-scope="scope">{{
              scope.row.s6_table_name
            }}</template>
          </el-table-column>
          <el-table-column :label="__('S6 PK')" v-show="!isChunk">
            <template slot-scope="scope">{{ scope.row.s6_pk }}</template>
          </el-table-column>
          <el-table-column :label="__('S6 Group')">
            <template slot-scope="scope">{{ scope.row.s6_group_id }}</template>
          </el-table-column>
          <el-table-column :label="__('S7 Table')" v-show="!isChunk">
            <template slot-scope="scope">{{
              scope.row.s7_table_name
            }}</template>
          </el-table-column>
          <el-table-column :label="__('S7 PK')" width="150px" v-show="!isChunk">
            <template slot-scope="scope">{{ scope.row.s7_pk }}</template>
          </el-table-column>
          <el-table-column
            :label="__('node_type')"
            width="150px"
            v-show="isChunk"
          >
            <template slot-scope="scope">{{ scope.row.node_type }}</template>
          </el-table-column>
          <el-table-column
            :label="__('total nodes')"
            width="150px"
            v-show="isChunk"
          >
            <template slot-scope="scope">{{ scope.row.total_nodes }}</template>
          </el-table-column>
          <el-table-column
            :label="__('chunk_count')"
            width="150px"
            v-show="isChunk"
          >
            <template slot-scope="scope">{{ scope.row.chunk_count }}</template>
          </el-table-column>
          <el-table-column
            label="chunk_migrated"
            width="150px"
            v-show="isChunk"
          >
            <template slot-scope="scope">{{
              scope.row.chunk_migrated
            }}</template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "MigrationMapComponent",
  props: {
    openModal: {
      type: Boolean,
      required: false,
      default: false
    },
    isChunk: {
      type: Boolean,
      required: false,
      default: false
    },
    fileUrl: {
      default: ""
    }
  },
  computed: {
    ...mapState("studio6serviceproviders", {
      mapData: state => state.migrationMap,
      total: state => state.total
    })
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    handleCancel() {
      this.$emit("cancel");
    }
  },
  watch: {
    openModal: {
      immediate: true,
      handler(val) {
        this.showModal = val;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top {
  text-align: center;
}
.item {
  margin: 4px;
}
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}
::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}
::v-deep .el-upload-dragger {
  &:hover {
    border-color: $content-theme-color !important;
  }
  .el-upload__text em {
    color: $content-theme-color;
  }
}
.downloadCsvStyle {
  color: $content-theme-color;
}
</style>
