<template>
  <div>
    <el-button class="createBtn" @click="migratePortal">{{
      __("Migrate SPs, ACs And OpenForms")
    }}</el-button>
    <el-button class="createBtn" @click="rollbackPortal">{{
      __("Rollback")
    }}</el-button>
    <el-steps :active="portalMigrationStep" class="p-4">
      <el-step
        :title="__('Step 1')"
        :description="__('Select Tables')"
        icon="el-icon-edit"
      ></el-step>
      <el-step
        :title="__('Step 2')"
        :description="__('Start Migration')"
        icon="el-icon-picture"
      ></el-step>
      <el-step
        :title="__('Step 3')"
        :description="__('Result')"
        icon="el-icon-picture"
      ></el-step>
    </el-steps>

    <el-table
      v-if="portalMigrationStep === 1"
      :key="1"
      ref="portalTablesRef"
      :data="portalTablesToMigrate"
      @selection-change="handleCheckedTablesChange"
      v-loading="studio6Loading"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column :label="__('Content')">
        <template slot-scope="scope">{{ scope.row.table_name }}</template>
      </el-table-column>
    </el-table>

    <el-table
      v-if="portalMigrationStep === 2"
      :key="2"
      ref="checkedTablesRef"
      :data="checkedPortalTables"
      :default-expand-all="true"
    >
      <el-table-column type="expand">
        <template slot-scope="props">
          <div class="migration-status">
            <p v-if="!props.row.migration_details">{{ __("Loading...") }}</p>
            <p
              v-for="(migration_details, index) in props.row.migration_details"
              :key="'migration_status_' + migration_details.table_name + index"
              :class="migration_details.status"
            >
              <el-tag :type="migration_details.status">{{
                migration_details.status
              }}</el-tag>
              <span style="padding-left: 5px;">{{
                migration_details.item_details
              }}</span>
              <span v-if="migration_details.error_reason">
                - {{ migration_details.error_reason }}</span
              >
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        :label="__('Content')"
        prop="table_name"
      ></el-table-column>
    </el-table>

    <el-table
      v-if="portalMigrationStep === 3"
      :key="3"
      ref="migrationResultsRef"
      :data="checkedPortalTables"
    >
      <el-table-column
        :label="__('Content')"
        prop="table_name"
      ></el-table-column>
      <el-table-column :label="__('Migrated')">
        <template slot-scope="scope">{{
          countItemsWithStatus(scope.row.table_name, "migrated")
        }}</template>
      </el-table-column>
      <el-table-column :label="__('Skipped')">
        <template slot-scope="scope">{{
          countItemsWithStatus(scope.row.table_name, "skipped")
        }}</template>
      </el-table-column>
      <el-table-column :label="__('Error')">
        <template slot-scope="scope">{{
          countItemsWithStatus(scope.row.table_name, "error")
        }}</template>
      </el-table-column>
    </el-table>

    <el-row type="flex" style="margin-top: 30px; padding-left: 0.7rem">
      <el-col :span="12" :offset="0">
        <el-button
          v-if="portalMigrationStep > 1"
          type="primary"
          class="cancelBtn"
          @click="portalMigrationStep--"
          v-loading="btnLoading"
          >{{ __("Previous Step") }}
        </el-button>

        <el-button
          v-if="portalMigrationStep < 4"
          type="primary"
          class="submitBtn"
          v-loading="btnLoading"
          @click="nextStep"
        >
          {{ nextStepButtonText }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import { EventBus } from "@/EventBus";
import { mapActions, mapState } from "vuex";
import _ from "lodash";
export default {
  data() {
    return {
      portalMigrationStep: 1,
      checkedPortalTables: [],
      btnLoading: false
    };
  },

  computed: {
    ...mapState("studio6serviceproviders", {
      studio6Loading: state => state.loading,
      portalTablesToMigrate: state => state.portalTablesToMigrate
    }),

    nextStepButtonText() {
      if (this.portalMigrationStep === 1) {
        return __("Start migration ->");
      }
      if (this.portalMigrationStep === 3) {
        return __("Done");
      }
      return __("Next Step");
    }
  },

  methods: {
    ...mapActions("studio6serviceproviders", {
      getPortalTablesToMigrate: "getPortalTablesToMigrate",
      migratePortalTable: "migratePortalTable",
      rollbackPortalTable: "rollbackPortalTable"
    }),

    handleCheckedTablesChange(value) {
      this.checkedPortalTables = value;
    },

    async migratePortal() {
      await this.migratePortalTable(null);
      this.portalMigrationStep = 2;
    },

    async rollbackPortal() {
      this.checkedPortalTables = _.sortBy(this.checkedPortalTables, "order");
      for (let table of this.checkedPortalTables) {
        await this.rollbackPortalTable(table.table_name);
      }
    },

    async nextStep() {
      if (this.portalMigrationStep === 1) {
        this.portalMigrationStep++; // start migration
        this.btnLoading = true;
        this.checkedPortalTables = _.sortBy(this.checkedPortalTables, "order");

        for (let table of this.checkedPortalTables) {
          await this.migratePortalTable(table.table_name);
        }
        this.btnLoading = false;
      } else if (this.portalMigrationStep === 3) {
        this.$emit("closedialog");
      } else {
        this.portalMigrationStep++;
      }
    },

    countItemsWithStatus(tableName, status) {
      let results = _.find(this.checkedPortalTables, { table_name: tableName })
        .migration_details;
      if (results) {
        return results.filter(ele => ele.status === status).length;
      }
      return 0;
    }
  },

  mounted() {
    this.getPortalTablesToMigrate();

    this.$echo
      .channel("portal_migration")
      .listen("PortalMigrationStats", eventData => {
        if (eventData && eventData.table_name) {
          let index = _.findIndex(this.checkedPortalTables, {
            table_name: eventData.table_name
          });

          if (index >= 0) {
            if (!_.has(this.checkedPortalTables[index], "migration_details")) {
              this.$set(
                this.checkedPortalTables[index],
                "migration_details",
                []
              );
            }
            this.checkedPortalTables[index].migration_details.push(eventData);
          } else {
            this.checkedPortalTables.push({
              table_name: eventData.table_name,
              migration_details: [eventData]
            });
          }
        }
      });
  },

  beforeDestroy: function() {
    this.$echo.leave("portal_migration");
  }
};
</script>

<style lang="scss" scoped>
//can ignore sp theme setting
.migration-status {
  .migrated {
    color: #67c23a;
    .el-tag.el-tag--migrated {
      background-color: #f0f9eb !important;
      border-color: #e1f3d8 !important;
      color: #67c23a !important;
    }
  }

  .skipped {
    color: #909399;
    .el-tag.el-tag--skipped {
      background-color: #f4f4f5 !important;
      border-color: #e9e9eb !important;
      color: #909399 !important;
    }
  }

  .error {
    color: #f56c6c;
    .el-tag.el-tag--error {
      background-color: #fef0f0 !important;
      border-color: #fde2e2 !important;
      color: #f56c6c !important;
    }
  }
}
</style>
