<template>
  <div v-loading="errorsLoading">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-select
          v-model="selectedGroupId"
          :placeholder="__('Group Id')"
          @change="updateVisibleJobIds"
          filterable
          default-first-option
        >
          <el-option
            v-for="item in groupIds"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select
          v-model="selectedErrorId"
          :placeholder="__('Migration Job Id')"
          filterable
          @change="getErrors"
        >
          <el-option
            v-for="item in jobIds"
            :key="item.index"
            :label="item.option"
            :value="item.migration_job_uuid"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="this.selectedErrorId !== ''">
        <el-button class="advancedBtn" @click="getErrors()">
          <img class="bulkImg" :src="getRefreshIcon" onload="SVGInject(this)" />
        </el-button>
      </el-col>
    </el-row>
    <el-row type="flex" style="padding-top: 20px">
      <el-col :span="6" v-if="this.selectedErrorId !== ''">
        <el-pagination
          :total="errorCount"
          layout="total,sizes,prev,pager,next"
          :page-sizes="[15, 30, 45, 60]"
          @size-change="handleSizeChange"
          @prev-click="handlePrevClick"
          @next-click="handleNextClick"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-table ref="migrationErrorsTableRef" :data="migration_errors">
      <el-table-column
        prop="migration_job_uuid"
        :label="__('Migration Job UUID')"
      >
      </el-table-column>
      <el-table-column prop="message" :label="__('Message')"> </el-table-column>
      <el-table-column prop="context" :label="__('Context')"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "MigrationError",
  computed: {
    ...mapState("studio6serviceproviders", {
      groupIdsWithErrors: state => state.groupIdsWithErrors
    }),
    getRefreshIcon() {
      return require("@/assets/icons/preview_table/refresh_ds_data.svg");
    }
  },

  mounted() {
    this.groupIds = Object.values(this.groupIdsWithErrors);
  },

  data() {
    return {
      selectedGroupId: "",
      selectedErrorId: "",
      groupIds: [],
      jobIds: [],
      migration_errors: [],
      showMigrationErrors: false,
      errorsLoading: false,
      errorCount: 0,
      pageCount: 15,
      page: 0
    };
  },
  methods: {
    ...mapActions("studio6serviceproviders", {
      checkMigrationErrors: "checkMigrationErrors",
      getGroupJobIdsWithErrors: "getGroupJobIdsWithErrors"
    }),
    updateVisibleErrors() {
      this.errorList = [];
      this.jobIds = [];
    },
    async updateVisibleJobIds() {
      this.selectedErrorId = "";
      this.errorsLoading = true;
      await this.getGroupJobIdsWithErrors({
        group_id: this.selectedGroupId
      })
        .then(data => {
          this.jobIds = _.get(data.data, this.selectedGroupId);
          this.jobIds.reverse();
          this.errorsLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.errorsLoading = false;
        });
    },
    async getErrors() {
      this.errorsLoading = true;
      await this.checkMigrationErrors({
        migration_job_uuid: this.selectedErrorId,
        per_page: this.pageCount,
        page: this.page
      })
        .then(data => {
          this.migration_errors = data.errors;
          this.errorCount = data.total;
          this.showMigrationErrors = true;
          this.errorsLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.errorsLoading = false;
        });
    },
    async handleSizeChange(value) {
      this.pageCount = value;
      this.page = 0;
      await this.getErrors();
    },

    async handlePrevClick() {
      this.page--;
      await this.getErrors();
    },

    async handleNextClick() {
      this.page++;
      await this.getErrors();
    },

    async handleCurrentChange(value) {
      this.page = value - 1;
      await this.getErrors();
    }
  }
};
</script>

<style scoped></style>
