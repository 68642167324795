<template>
  <el-container class="h-full">
    <el-container>
      <el-main>
        <div style="height: 50px; ">
          <page-header
            style="padding-left: 0.7rem; width: 100%"
            :title="__('The Great Migration')"
          >
          </page-header>
          <el-button
            style="margin-right: 340px"
            class="createBtn"
            @click="handleCheckJobList"
            :loading="jobsLoading"
            >{{ __("Check Job List") }}</el-button
          >

          <el-button
            style="margin-right: 150px"
            class="createBtn"
            @click="handleCheckMigrationErrors"
            :loading="errorsLoading"
            >{{ __("Check Migration Errors") }}</el-button
          >

          <el-button
            class="createBtn"
            @click="portalMigrationDialogVisible = true"
            >{{ __("Migrate Portal") }}</el-button
          >
        </div>
        <migrate-account-form />

        <el-dialog
          :title="__('Portal Migration')"
          :visible.sync="portalMigrationDialogVisible"
          :show-close="true"
          destroy-on-close
          width="50%"
          v-if="portalMigrationDialogVisible"
        >
          <migrate-portal-form @closedialog="handleClosePortalDialog" />
        </el-dialog>
        <el-dialog
          :title="__('Migration Errors')"
          :visible.sync="migrationErrorDialogVisible"
          :show-close="true"
          destroy-on-close
          width="50%"
          v-if="migrationErrorDialogVisible"
        >
          <migration-error @closedialog="handleClosePortalDialog" />
        </el-dialog>
        <el-dialog
          :title="__('Migration Job List')"
          :visible.sync="migrationJobDialogVisible"
          :show-close="true"
          destroy-on-close
          width="50%"
          v-if="migrationJobDialogVisible"
        >
          <migration-job-list @closedialog="handleClosePortalDialog" />
        </el-dialog>
      </el-main>
    </el-container>
    <el-aside class="form-aside relative" :width="`${asideWidth}px`">
      <div class="aside-content">
        <div style="font-size: 14px;padding: 30px 15px;color: #A0A8B5">
          {{ asideText }}
        </div>
      </div>
      <slot name="form"></slot>
    </el-aside>
  </el-container>
</template>
<script>
import { mapState, mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import MigrateAccountForm from "./pages/MigrateAccountForm";
import MigratePortalForm from "./pages/MigratePortalForm";
import MigrationError from "./pages/MigrationError";
import MigrationJobList from "./pages/MigrationJobList";

export default {
  name: "MigrateAccountIndex",
  components: {
    PageHeader,
    MigrateAccountForm,
    MigratePortalForm,
    MigrationError,
    MigrationJobList
  },
  props: {
    formAction: {
      default: ""
    },
    asideText: {
      default: ""
    },
    asideButtonText: {
      default: "Create"
    },
    asideWidth: {
      type: Number,
      required: false,
      default: 274
    }
  },
  data() {
    return {
      activeName: "connect",
      portalMigrationDialogVisible: false,
      migrationErrorDialogVisible: false,
      migrationJobDialogVisible: false,
      errorsLoading: false,
      jobsLoading: false
    };
  },

  computed: {
    ...mapState("systemsettings", {
      contents: state => state.settings
    }),
    ...mapState("studio6serviceproviders", {
      migrationErrors: state => state.migrationErrors
    })
  },

  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForServiceProvider: "fetchAccountsForServiceProvider",
      fetchTrunksForServiceProvider: "fetchTrunksForServiceProvider",
      getServiceProviders: "getServiceProviders"
    }),
    ...mapActions("studio6serviceproviders", {
      fetchStudio6AccountsForServiceProvider: "fetchAccountsForSelectedSP",
      getStudio6ServiceProviders: "getAllServiceProviders",
      getMigrationErrorList: "getMigrationErrorList",
      getS6ServiceProviders: "getS6ServiceProviders",
      getGroupIdsWithErrors: "getGroupIdsWithErrors",
      getGroupIdsWithJobs: "getGroupIdsWithJobs",
      getMappedGroupIds: "getMappedGroupIds"
    }),

    handleClosePortalDialog() {
      this.portalMigrationDialogVisible = false;
      this.migrationErrorDialogVisible = false;
      this.migrationJobDialogVisible = false;
    },
    async handleCheckMigrationErrors() {
      this.errorsLoading = true;
      await this.getGroupIdsWithErrors()
        .then(() => (this.errorsLoading = false))
        .catch(err => {
          console.log(err);
          this.errorsLoading = false;
        });
      this.errorsLoading = false;
      this.migrationErrorDialogVisible = true;
    },
    async handleCheckJobList() {
      this.jobsLoading = true;
      await this.getMappedGroupIds()
        .then(() => (this.jobsLoading = false))
        .catch(err => {
          console.log(err);
          this.jobsLoading = false;
        });
      this.jobsLoading = false;
      this.migrationJobDialogVisible = true;
    }
  },
  mounted() {
    // this.fetchAccountsForServiceProvider();
    // this.fetchTrunksForServiceProvider();
    // this.getServiceProviders();
    // this.fetchStudio6AccountsForServiceProvider();
    // this.getStudio6ServiceProviders();
    // this.getS6ServiceProviders();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/content-buttons.scss";

.h-full {
  height: 100%;
}

.aside-content {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.form-aside {
  background: white;
  border-left: #f5f5f8 1px solid;
}
</style>
