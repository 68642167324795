<template>
  <div v-loading="jobsLoading">
    <el-row :gutter="20">
      <el-col :span="6">
        <el-select
          v-model="selectedGroupId"
          :placeholder="__('Group Id')"
          @change="updateVisibleJobIds"
          filterable
          default-first-option
        >
          <el-option
            v-for="item in groupIds"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-select
          v-model="selectedJobId"
          :placeholder="__('Migration Job Id')"
          @change="getJobInfo"
          filterable
          default-first-option
        >
          <el-option
            v-for="item in jobIds"
            :key="item.migration_job_uuid"
            :label="item.option"
            :value="item.migration_job_uuid"
            class="migration-job-item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="this.selectedJobId !== ''">
        <el-select
          v-model="statusFilter"
          placeholder="Status"
          @change="handleFilterChange"
        >
          <el-option
            v-for="item in filterOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" v-if="this.selectedJobId !== ''">
        <el-button class="advancedBtn" @click="getJobInfo()">
          <img class="bulkImg" :src="getRefreshIcon" onload="SVGInject(this)" />
        </el-button>
      </el-col>
    </el-row>
    <el-row type="flex" style="padding-top: 20px">
      <el-col :span="6" v-if="this.selectedJobId !== ''">
        <el-pagination
          :total="jobCount"
          layout="total,sizes,prev,pager,next"
          :page-sizes="[15, 30, 45, 60]"
          @size-change="handleSizeChange"
          @prev-click="handlePrevClick"
          @next-click="handleNextClick"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <el-table ref="migrationJobTableRef" :data="job_list">
      <el-table-column prop="content_type" :label="__('Content Type')">
      </el-table-column>
      <el-table-column prop="queue_id" :label="__('Queue ID')">
      </el-table-column>
      <el-table-column prop="status" :label="__('Status')"> </el-table-column>
      <el-table-column prop="context" :label="__('Context')"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from "lodash";

export default {
  name: "MigrationJobList",
  computed: {
    ...mapState("studio6serviceproviders", {
      migrationJobList: state => state.migrationJobList,
      groupIdsWithJobs: state => state.groupIdsWithJobs,
      mappedGroupIds: state => state.mappedGroupIds
    }),
    getRefreshIcon() {
      return require("@/assets/icons/preview_table/refresh_ds_data.svg");
    }
  },
  mounted() {
    this.groupIds = Object.values(this.mappedGroupIds);
  },
  data() {
    return {
      selectedGroupId: "",
      selectedJobId: "",
      groupIds: [],
      jobIds: [],
      job_list: [],
      jobsLoading: false,
      pageCount: 15,
      page: 0,
      jobCount: 0,
      filterOptions: [
        {
          value: "",
          label: "All"
        },
        {
          value: "Complete",
          label: "Complete"
        },
        {
          value: "Running",
          label: "Running"
        },
        {
          value: "Failed",
          label: "Failed"
        }
      ],
      statusFilter: ""
    };
  },
  methods: {
    ...mapActions("studio6serviceproviders", {
      getMigrationJobInfo: "getMigrationJobInfo",
      getJobIdsForGroup: "getJobIdsForGroup"
    }),
    async updateVisibleJobIds() {
      this.jobsLoading = true;
      await this.getJobIdsForGroup({
        group_id: this.selectedGroupId
      })
        .then(data => {
          this.jobIds = _.get(data.data, this.selectedGroupId, []);
          this.jobIds.reverse();
          this.jobsLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.jobsLoading = false;
        });
    },
    async getJobInfo() {
      await this.getMigrationJobInfo({
        migration_job_uuid: this.selectedJobId,
        per_page: this.pageCount,
        page: this.page,
        filter: this.statusFilter
      }).then(data => {
        this.job_list = data.job_list;
        this.jobCount = data.total;
      });
    },
    async handleSizeChange(value) {
      this.pageCount = value;
      this.page = 0;
      await this.getJobInfo();
    },
    async handlePrevClick() {
      this.page--;
      await this.getJobInfo();
    },
    async handleNextClick() {
      this.page++;
      await this.getJobInfo();
    },
    async handleCurrentChange(value) {
      this.page = value - 1;
      await this.getJobInfo();
    },

    async handleFilterChange() {
      this.page = 0;
      await this.getJobInfo();
    }
  }
};
</script>
<style scoped>
.el-select-dropdown__item {
  white-space: normal;
  word-wrap: break-word;
}

.migration-job-item {
  max-width: 36vw;
  height: 100%;
}
</style>
