<template>
  <div>
    <el-tabs v-model="active" @tab-click="handleClick">
      <el-tab-pane :label="__('Service Providers')" name="sp">
        <el-row type="flex" style="padding-top: 20px">
          <el-col :span="6">
            <el-pagination
              :total="spCount"
              layout="total,sizes,prev,pager,next"
              :page-sizes="[15, 30, 45, 60]"
              @size-change="handleSizeChange"
              @prev-click="handlePrevClick"
              @next-click="handleNextClick"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            ref="unmappedSpsRef"
            :data="this.unmappedSps.data"
            v-loading="studio6spLoading"
          >
            <el-table-column prop="id" :label="__('S6 ID')"> </el-table-column>
            <el-table-column
              prop="name"
              :label="__('S6 Service Provider')"
            ></el-table-column>
            <el-table-column
              prop="mapped_s7_sp_id"
              :label="__('S7 ID')"
            ></el-table-column>
            <el-table-column
              :label="__('S7 Service Provider')"
              prop="mapped_sp_name"
            >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button @click="remapSp(scope.row)">{{
                  __("Unmap")
                }}</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-tab-pane>
      <el-tab-pane :label="__('Accounts')" name="ac">
        <el-row type="flex" style="padding-top: 20px">
          <el-col :span="6">
            <el-pagination
              :total="acCount"
              layout="total,sizes,prev,pager,next"
              :page-sizes="[15, 30, 45, 60]"
              @size-change="handleAcSizeChange"
              @prev-click="handleAcPrevClick"
              @next-click="handleAcNextClick"
              @current-change="handleAcCurrentChange"
            >
            </el-pagination>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            ref="unmappedAcsRef"
            :data="this.unmappedAcs.data"
            v-loading="studio6spLoading"
          >
            <el-table-column prop="id" :label="__('S6 ID')"> </el-table-column>
            <el-table-column
              prop="name"
              :label="__('S6 Account')"
            ></el-table-column>
            <el-table-column
              prop="mapped_s7_ac_id"
              :label="__('S7 Account ID')"
            ></el-table-column>
            <el-table-column
              prop="mapped_s7_sp_id"
              :label="__('S7 Service Provider ID')"
            ></el-table-column>
            <el-table-column
              :label="__('S7 Account')"
              prop="mapped_ac_name"
            ></el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button @click="remapAc(scope.row)">{{
                  __("Unmap")
                }}</el-button>
              </template>
            </el-table-column>
          </el-table></el-row
        ></el-tab-pane
      >
      <el-tab-pane :label="__('Unused Accounts')" name="unusedAccountsTab">
        <el-row type="flex" style="padding-top: 20px">
          <el-button @click="deleteSelectedAccounts"
            >Delete Selected Accounts</el-button
          >
        </el-row>
        <el-row type="flex" style="padding-top: 20px">
          <el-table
            ref="unusedAcRef"
            :data="this.unusedAccounts"
            v-loading="unusedAccountsLoading"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="s6_group_id" :label="__('S6 Group ID')">
            </el-table-column>
            <el-table-column
              prop="account"
              :label="__('Account Name')"
            ></el-table-column>
            <el-table-column
              prop="s7_ac_id"
              :label="__('S7 Account ID')"
            ></el-table-column>
            <el-table-column
              prop="s7_sp_id"
              :label="__('S7 Service Provider ID')"
            ></el-table-column>
          </el-table>
        </el-row>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "UnmappedSpAc",
  computed: {
    ...mapState("studio6serviceproviders", {
      unmappedSps: state => state.unmappedSps,
      unmappedAcs: state => state.unmappedAcs,
      studio6spLoading: state => state.loading,
      accountsForRemapping: state => state.accountsForRemapping,
      unusedAccounts: state => state.unusedAccounts,
      unusedAccountsLoading: state => state.unusedAccountsLoading
    }),
    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders
    }),
    spCount() {
      return this.unmappedSps.total;
    },
    acCount() {
      return this.unmappedAcs.total;
    }
  },
  data() {
    return {
      active: "sp",
      s7_sp_id: 0,
      s7_ac_id: 0,
      pageCount: 15,
      page: 0,
      acPageCount: 15,
      acPage: 0,
      unusedAccountsToDelete: []
    };
  },
  methods: {
    ...mapActions("studio6serviceproviders", {
      fetchAccountsForSelectedStudio6SP: "fetchAccountsForSelectedSP",
      validateAccountContent: "validateAccountContent",
      migrateAccountContent: "migrateAccountContent",
      rollbackAccountContent: "rollbackAccountContent",
      reportMigrationError: "reportMigrationError",
      rollbackAccountTable: "rollbackAccountTable",
      getMigrationMap: "getMigrationMap",
      consolidateNodeMigrationProgress: "consolidateNodeMigrationProgress",
      publishTemplates: "publishTemplates",
      checkMigrationErrors: "checkMigrationErrors",
      getS6ServiceProviders: "getS6ServiceProviders",
      getMappedS7Sp: "getMappedS7Sp",
      mapS6SpToS7Sp: "mapS6SpToS7Sp",
      getS6AccountsForSp: "getS6AccountsForSp",
      getMappedS7Ac: "getMappedS7Ac",
      mapS6AcToS7Ac: "mapS6AcToS7Ac",
      validateSelectedSp: "validateSelectedSp",
      validateSelectedAc: "validateSelectedAc",
      getUnmappedServiceProviders: "getUnmappedServiceProviders",
      getUnmappedAccounts: "getUnmappedAccounts",
      unmapServiceProvider: "unmapServiceProvider",
      unmapAccount: "unmapAccount",
      getUnusedAccounts: "getUnusedAccounts",
      deleteUnusedAccounts: "deleteUnusedAccounts"
    }),
    async remapSp(sp) {
      if (!sp.allow_remapping) {
        this.$alert(
          __("The selected service provider cannot be unmapped."),
          __("Remap Service Provider"),
          {
            confirmButtonText: __("OK"),
            callback: () => {}
          }
        );
      } else {
        let s7_sp = sp.mapped_s7_sp_id;
        await this.unmapServiceProvider({
          s7_sp: s7_sp
        })
          .then(() => {
            this.$message({
              type: "success",
              message: __("Service provider unmapped successfully.")
            });
          })
          .catch(err => {
            console.log(err);
            this.isSubmitting = false;
            this.$message({
              type: "error",
              message: err.message
            });
          });
      }
    },
    async remapAc(ac) {
      if (!ac.allow_remapping) {
        this.$alert(
          __("The selected account cannot be remapped."),
          __("Remap Account"),
          {
            confirmButtonText: __("OK"),
            callback: () => {}
          }
        );
      } else {
        let s7_ac = ac.mapped_s7_ac_id;
        await this.unmapAccount({
          s7_ac: s7_ac
        })
          .then(() => {
            this.$message({
              type: "success",
              message: __("Account unmapped successfully.")
            });
          })
          .catch(err => {
            console.log(err);
            this.isSubmitting = false;
            this.$message({
              type: "error",
              message: err.message
            });
          });
      }
    },

    handleSpSelection(sp) {
      this.s7_sp_id = sp;
    },

    handleAcSelection(ac) {
      this.s7_ac_id = ac;
    },

    async getUnmappedSps() {
      this.getUnmappedServiceProviders({
        page: this.page,
        per_page: this.pageCount
      });
    },

    async getUnmappedAcs() {
      this.getUnmappedAccounts({
        page: this.acPage,
        per_page: this.acPageCount
      });
    },

    async handleClick() {
      if (this.active === "unusedAccountsTab") {
        await this.getUnusedAccounts();
      }
    },

    handleSelectionChange(val) {
      this.unusedAccountsToDelete = val;
    },

    deleteSelectedAccounts() {
      this.$confirm(
        // eslint-disable-next-line
          __("This will delete the selected accounts and their migration maps. Continue?"),
        "Warning",
        {
          confirmButtonText: __("Confirm"),
          cancelButtonText: __("Cancel"),
          type: "warning",
          showClose: true
        }
      )
        .then(async () => {
          await this.deleteUnusedAccounts(this.unusedAccountsToDelete)
            .then(async () => {
              this.$message({
                type: "success",
                message: __("Deletion completed")
              });
              await this.getUnusedAccounts();
            })
            .catch(err => {
              this.$notify({
                message: err,
                type: "error"
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: __("Deletion cancelled")
          });
        });
    },

    async handleSizeChange(value) {
      this.pageCount = value;
      this.page = 0;
      await this.getUnmappedSps();
    },
    async handlePrevClick() {
      this.page--;
      await this.getUnmappedSps();
    },
    async handleNextClick() {
      this.page++;
      await this.getUnmappedSps();
    },
    async handleCurrentChange(value) {
      this.page = value - 1;
      await this.getUnmappedSps();
    },

    async handleAcSizeChange(value) {
      this.acPageCount = value;
      this.acPage = 0;
      await this.getUnmappedAcs();
    },
    async handleAcPrevClick() {
      this.acPage = 0;
      await this.getUnmappedAcs();
    },
    async handleAcNextClick() {
      this.acPage = 0;
      await this.getUnmappedAcs();
    },
    async handleAcCurrentChange(value) {
      this.acPage = value - 1;
      await this.getUnmappedAcs();
    }
  }
};
</script>

<style scoped></style>
