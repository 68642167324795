<template>
  <div>
    <div style="margin-bottom:10px">
      Total selected tasks: {{ selectedTasks.length }}
    </div>
    <el-alert
      v-if="!canSelectMore"
      title="Warning"
      type="warning"
      description="You cannot select more tasks"
      show-icon
    ></el-alert>
    <div v-for="(tasks, type) in taskData" :key="type">
      <div @click="toggleCollapse(type)">
        <el-button type="text" @click.stop="toggleCollapse(type)">
          <i
            :class="
              isCollapsed(type) ? 'el-icon-arrow-right' : 'el-icon-arrow-down'
            "
          ></i>
        </el-button>
        <span style="padding-left: 10px"
          >{{ type }} ({{ getSelectedCount(type) }})</span
        >
      </div>
      <el-collapse-transition>
        <div v-show="!isCollapsed(type)">
          <div style="margin: 6px 0 10px 26px;">
            <el-button
              type="primary"
              plain
              size="mini"
              @click.prevent="selectTasks(type)"
              :disabled="!canSelectMore"
              >Add Next 10</el-button
            >
            <el-button
              type="danger"
              plain
              size="mini"
              @click.prevent="deselectLast10(type)"
              >Deselect Last 10</el-button
            >
            <el-button
              type="danger"
              plain
              size="mini"
              @click.prevent="deselectAll(type)"
              >Deselect All</el-button
            >
          </div>

          <el-checkbox-group
            style="margin-left: 26px"
            v-model="selectedTasks"
            @change="taskSelectionChanged"
          >
            <el-checkbox
              v-for="task in tasks"
              :label="task.value"
              :key="task.value"
              :disabled="!canSelectMore && !selectedTasks.includes(task.value)"
            >
              {{ task.label }}
            </el-checkbox>
          </el-checkbox-group>
        </div>
      </el-collapse-transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    taskData: {
      type: [Object, Array],
      default: () => {}
    },

    canSelectMore: {
      type: Boolean,
      default: true
    },

    tasksAllowed: {
      type: Number,
      default: 50
    }
  },

  data() {
    return {
      selectedTasks: [],
      tasksShown: {},
      collapsedTypes: {}
    };
  },
  methods: {
    selectTasks(type) {
      if (!this.tasksShown[type]) {
        this.$set(this.tasksShown, type, 0);
      }
      const startIndex = this.tasksShown[type];
      const availableTasksToAdd = Math.min(this.tasksAllowed, 10);
      let endIndex = startIndex + availableTasksToAdd;

      if (endIndex > this.taskData[type].length) {
        endIndex = this.taskData[type].length;
      }
      this.tasksShown[type] = endIndex;

      const newTasks = this.taskData[type]
        .slice(startIndex, endIndex)
        .map(task => task.value);
      this.selectedTasks = [...new Set([...this.selectedTasks, ...newTasks])];
      this.$emit("update:selectedTasks", this.selectedTasks);
    },
    deselectLast10(type) {
      const tasksOfType = this.taskData[type].map(task => task.value);
      const selectedTasksOfType = this.selectedTasks.filter(task =>
        tasksOfType.includes(task)
      );
      const tasksToRemove = selectedTasksOfType.slice(-10);

      this.selectedTasks = this.selectedTasks.filter(
        task => !tasksToRemove.includes(task)
      );
      this.tasksShown[type] -= tasksToRemove.length;
      if (this.tasksShown[type] < 0) {
        this.tasksShown[type] = 0;
      }
      this.taskSelectionChanged();
    },
    deselectAll(type) {
      const tasksOfType = this.taskData[type].map(task => task.value);
      this.selectedTasks = this.selectedTasks.filter(
        task => !tasksOfType.includes(task)
      );
      this.tasksShown[type] = 0;
      this.taskSelectionChanged();
    },
    taskSelectionChanged() {
      this.$emit("update:selectedTasks", this.selectedTasks);
    },
    toggleCollapse(type) {
      this.$set(this.collapsedTypes, type, !this.collapsedTypes[type]);
    },
    isCollapsed(type) {
      return this.collapsedTypes[type];
    },
    getSelectedCount(type) {
      return this.selectedTasks.filter(task =>
        this.taskData[type].some(t => t.value === task)
      ).length;
    }
  },
  created() {
    Object.keys(this.taskData).forEach(type => {
      this.$set(this.tasksShown, type, 0);
      this.$set(this.collapsedTypes, type, false);
    });
  }
};
</script>

<style scoped>
.el-checkbox {
  display: block;
  margin: 10px 0;
}
</style>
